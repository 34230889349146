import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Meadowbrook Golf Haven
			</title>
			<meta name={"description"} content={"Meadowbrook Golf Haven FAQs - Uncovering Every Detail for You"} />
			<meta property={"og:title"} content={"FAQ | Meadowbrook Golf Haven"} />
			<meta property={"og:description"} content={"Meadowbrook Golf Haven FAQs - Uncovering Every Detail for You"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/ball_1.jpg?v=2023-10-24T09:08:20.037Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="--color-primary" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Meadowbrook Golf Haven FAQs
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#b2bac1" sm-margin="0px 0px 50px 0px">
				Navigate the ins and outs of Meadowbrook Golf Haven with our comprehensive FAQ section, addressing everything from tee times and training to equipment rentals and dress codes.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Do I need to be a member to play at Meadowbrook Golf Haven?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						While membership offers a range of benefits, we welcome non-members to experience our course. Kindly check our booking by contacting support for available tee times and rates.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Can I rent equipment if I don’t have my own?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Absolutely! We offer a range of high-quality clubs, trolleys, carts, and essential accessories for rental. Our team is available to assist you in selecting the best equipment for your needs.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						I'm new to golf. Do you offer beginner lessons?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Yes, our Meadowbrook Masters provide tailored training sessions for players of all levels, including beginners. You can opt for individual or group lessons based on your preference.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Is there a dress code?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						We maintain a traditional golf attire policy. This includes collared shirts, tailored shorts or trousers, and golf shoes. We kindly ask our guests to refrain from wearing denim, tank tops, or open-toed shoes on the course.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Can I host a private event or tournament at Meadowbrook?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Certainly! Meadowbrook Golf Haven offers event hosting services. Reach out to our events team for more details on packages and availability.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Are children allowed on the course?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Children are welcome at Meadowbrook. We offer junior training programs, and kids under a certain age can play for free when accompanied by a paying adult. Do check our policy on age restrictions for unaccompanied minors.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});